import React, { useState, useEffect, useRef } from "react";
import AnimationStyle from "./help-steps/animationStyle";
import helpSteps from "./help-steps/helpSteps";

const ProjectSetupHelp = () => {
  const [copiedStates, setCopiedStates] = useState(helpSteps.map(() => false));
  const [dotCount, setDotCount] = useState(0);
  const timeoutIdsRef = useRef([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotCount((prev) => (prev >= 3 ? 0 : prev + 1));
    }, 500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    return () => {
      timeoutIdsRef.current.forEach((id) => clearTimeout(id));
    };
  }, []);

  const handleCopy = async (text, index) => {
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(text);
      } else {
        throw new Error("Clipboard API not available");
      }

      setCopiedStates((prev) => {
        const newStates = [...prev];
        newStates[index] = true;
        return newStates;
      });

      const timerId = setTimeout(() => {
        setCopiedStates((prev) => {
          const newStates = [...prev];
          newStates[index] = false;
          return newStates;
        });

        timeoutIdsRef.current = timeoutIdsRef.current.filter(
          (id) => id !== timerId
        );
      }, 2000);

      timeoutIdsRef.current.push(timerId);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  const dotString = ".".repeat(dotCount);

  return (
    <AnimationStyle className="flex flex-col items-center min-h-screen p-4">
      <div className="bg-white rounded-lg shadow-md p-6 max-w-lg w-full">
        <h1 className="text-xl font-bold text-secondary-800 mb-4">
          Project Setup Help
        </h1>

        <div className="flex flex-col items-center mb-6">
          <svg
            className="w-12 h-12 animate-color-cycle"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <h2 className="mt-2 text-secondary-700 text-lg font-semibold">
            Waiting For Data
            <span className="inline-block w-8 text-left">{dotString}</span>
          </h2>
        </div>

        <p className="text-secondary-600 mb-6">
          It looks like you haven't set up any projects yet. We'll help you fix
          that. Follow the steps below to install the CLI, add a
          token, initialize a project, run and deploy your first project to a stage
          called "production."
        </p>

        <div className="border-t border-secondary-200 pt-4">
          <p className="font-semibold mb-2">Please follow these steps:</p>
          <ol className="list-decimal border-2 border-highlight-500 p-4 list-inside space-y-6">
            {helpSteps.map((step, i) => {
              const isCopied = copiedStates[i];
              return (
                <li key={i}>
                  <span className="font-medium">{step.label}</span>

                  <div className="flex justify-center mt-2">
                    <div className="w-full max-w-md">
                      <div className="relative flex">
                        <code className="flex w-full items-center rounded-l-lg bg-secondary-100 p-3 text-sm text-secondary-900 dark:bg-secondary-800 dark:text-secondary-100">
                          <span className="text-secondary-300 dark:text-secondary-400 mr-2">
                            $
                          </span>
                          <span>{step.command}</span>
                        </code>
                        <button
                          className="rounded-r-lg bg-secondary-200 px-4 py-2 hover:bg-secondary-300 dark:bg-secondary-600 dark:hover:bg-secondary-500 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                          onClick={() => handleCopy(step.command, i)}
                          disabled={isCopied}
                        >
                          {isCopied ? (
                            <svg
                              className="h-4 w-4 text-green-700"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 00-1.414 0L9
                                   11.586 6.707 9.293a1 1 0 00-1.414
                                   1.414l3 3a1 1 0 001.414 0l7-7a1
                                   1 0 000-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : (
                            <svg
                              className="h-4 w-4 text-secondary-800 dark:text-secondary-200"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                              <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                            </svg>
                          )}
                        </button>
                        {isCopied && (
                          <div className="absolute -top-8 right-0 rounded bg-secondary-800 px-2 py-1 text-sm text-white">
                            Copied to clipboard!
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </AnimationStyle>
  );
};

export default ProjectSetupHelp;
