import React from "react";
import stageLabel from "./utils/stageLabel";

const StageDropdownItem = ({ currentStageName, stages, defaultStageId }) => {
  if (!currentStageName || !defaultStageId) {
    return null;
  }

  return (
    <div className="flex items-center justify-between w-full">
      <div className="font-medium text-gray-800 truncate">{currentStageName}</div>
      {stageLabel(currentStageName, stages, defaultStageId)}
    </div>
  );
};
export default StageDropdownItem;