import {
  Link,
  useLoaderData,
  useNavigate,
  useRouteLoaderData,
  useRevalidator,
} from "react-router-dom";
import { isEmpty } from "lodash";
import { projectPath } from "../../models/Paths";
import { useState, useEffect } from "react";
import Heading from "../../viewer/components/styled/Heading";
import Stages from "./Stages";
import ProjectSetupHelp from "./ProjectSetupHelp";

const alphaSort = (a, b) => (a.name > b.name ? 1 : -1);

const DefaultStageContainer = () => {
  const navigate = useNavigate();
  const defaultStageData = useLoaderData();
  const stageData = useRouteLoaderData("stages");
  const revalidator = useRevalidator();

  useEffect(() => {
    if (stageData?.stages?.some((stage) => stage.name)) {
      return;
    }
    const interval = setInterval(() => {
      revalidator.revalidate();
    }, 7000);
    return () => clearInterval(interval);
  }, [revalidator, stageData]);

  const [defaultStageExists, setDefaultStageExists] = useState(
    !isEmpty(defaultStageData.defaultStage)
  );

  const handleSetDefaultChange = async () => {
    if (!defaultStageExists) {
      setDefaultStageExists(true);
      navigate(".", { replace: true });
    }
  };

  if (isEmpty(stageData.stages)) {
    return <ProjectSetupHelp />;
  }

  return (
    <>
      {!defaultStageExists ? (
        <>
          <div id="alert-1" className="inline-flex mt-4 items-center p-4 mb-4 text-[#713B57] rounded-lg bg-[#713B57]/10" role="alert">
            <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8 4a.75.75 0 01-.75-.75V10a.75.75 0 011.5 0v3.25A.75.75 0 0110 14zm0-6a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
            </svg>
            <span className="sr-only">Info</span>
            <div className="ml-3 text-sm font-medium">
              You do not have any default stages set. Please create and/or set one.
            </div>
          </div>
          <Stages
            canSetDefault={!defaultStageData.defaultStage}
            onSetDefault={handleSetDefaultChange}
          />
        </>
      ) : (
        <>
          <Heading>Stage</Heading>
          {defaultStageData.defaultStage?.name}
          <Heading>Projects</Heading>
          <ol>
            {defaultStageData.projects.sort(alphaSort).map((project) => (
              <li
                key={`${project.id}`}
                className="flex flex-row justify-between items-center"
              >
                <Link
                  to={projectPath(
                    defaultStageData.accountSlug,
                    defaultStageData.defaultStage.name,
                    project.name
                  )}
                >
                  {project.name}
                </Link>
                <span>
                  {new Date(Date.parse(project.created_at)).toLocaleString()}
                </span>
              </li>
            ))}
          </ol>
        </>
      )}
    </>
  );
};

export default DefaultStageContainer;
