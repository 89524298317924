import React, { useState } from "react";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { createToken } from "../../api/tokens";
import { ButtonHighlight } from "../../viewer/components/styled/Button";

const AuthorizeDevice = () => {
  const  [searchParams] = useSearchParams();

  const name = searchParams.get("name") || "default";
  const redirect_url = searchParams.get("redirect_url") || "http://localhost:5001/authorize-device-token";

  const [machineName, setMachineName] = useState(name);
  const loaderData = useLoaderData();

  const authorizeDevice = async () => {
    if (machineName.trim().length < 4) return;
    try {
      const tokenName = `CLI-${machineName}`;
      const token = await createToken(tokenName);

      window.location.href = `${redirect_url}?token=${token.key}`;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-secondary-50 p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-md p-8">
        <h1 className="text-2xl font-bold text-secondary-800 mb-4">
          Hi there Visivo CLI user
        </h1>
          <p>
            You are here to authorize your device named{" "} 
            <span className="text-indigo-600">{machineName}</span> for <span className="text-indigo-600">{loaderData.name}</span>. because you
            ran the command from your Visivo command line "<b>visivo authorize</b>". If
            you did not run this command, please close this page without
            authorizing.
          </p>
        <p className="text-secondary-600 mb-2">
          To authorize your device, please click the button below.
        </p>
        <p className="text-secondary-600">
          Your token will be stored in ~/.visivo/profile.yml and will be named:
        </p>
        <p className="text-center text-red-600 font-bold mb-4">
          CLI-{machineName}
        </p>
        <div className="mb-4">
          <label htmlFor="machineName" className="block text-secondary-700 mb-1">
            Edit Device Name:
          </label>
          <input
            id="machineName"
            type="text"
            value={machineName}
            onChange={(e) => setMachineName(e.target.value)}
            className="w-full border border-secondary-300 rounded px-3 py-2"
            placeholder="Enter device name"
          />

          {machineName.trim().length < 4 && (
            <p className="text-red-500 text-sm mt-1">
              Device name must be at least 4 characters long.
            </p>
          )}
        </div>
        <ButtonHighlight
          onClick={authorizeDevice}
          className="btn btn-primary w-full"
          disabled={machineName.trim().length < 4}
        >
          Authorize Device "{machineName}"
        </ButtonHighlight>
        <p className="mt-4">
          You can delete any tokens that have created on this account here{" "}
          <a
            className="btn btn-info w-full bg-secondary-100"
            href="https://visivo.io/profile/settings"
          >
            Token Page
          </a>
        </p>
      </div>
    </div>
  );
};

export default AuthorizeDevice;
