const helpSteps = [
  { label: "Install CLI:", command: "pip install visivo" },
  { label: "Login:", command: "visivo authorize" },
  { label: "Initialize Project:", command: "visivo init" },
  {
    label: "Go into your project directory:",
    command: "cd <project name>",
  },
  { label: "Run Project:", command: "visivo run" },
  {
    label: "Deploy project:",
    command: "visivo deploy -s hello_world",
  },
];

export default helpSteps;
