import { isEmpty } from "lodash";

const stageLabel = (currentStageName, stages, defaultStageId) => {
  if (isEmpty(currentStageName)) {
    return null;
  }

  const currentStage = stages.find((stage) => stage.name === currentStageName);
  const isArchived = !currentStage;

  if (isArchived) {
    return (
      <span
        className="text-xs w-20 text-center text-white bg-highlight-500 font-semibold rounded px-2 py-1"
        role="alert"
      >
        Archived
      </span>
    );
  }

  const isDefaultStage = currentStage.id === defaultStageId;

  if (!isDefaultStage) {
    return (
      <span
        className="text-xs w-20 text-center text-white bg-secondary-500 font-semibold rounded px-2 py-1"
        role="alert"
      >
        Custom
      </span>
    );
  }

  if (isDefaultStage) {
    return (
      <span
        className="text-xs w-20 text-center text-white bg-primary-500 font-semibold rounded px-2 py-1"
        role="alert"
      >
        Default
      </span>
    );
  }

  return null;
};

export default stageLabel;
