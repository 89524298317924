import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../viewer/components/Loading";
import { activateEmail } from "../../api/register";
import ErrorPage from "../ErrorPage";

const message = encodeURIComponent("Account activated successfully, please login.")

const Activate = () => {
    const navigate = useNavigate();
    const { uid, token } = useParams();
    const [error, setError] = useState(null)
    useEffect(() => {
        const activate = async () => {
            try {
                await activateEmail(uid, token)
                navigate(`/login?message=${message}`)
            } catch (e) {
                setError(await e.json())
            }
        }
        if (uid && token && navigate) {
            activate()
        }
    }, [uid, token, navigate]);

    if (error) {
        return <ErrorPage message={error} />
    } else {
        return <Loading />
    }
}

export default Activate;
