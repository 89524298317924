import { useState } from "react";

import { getSessionUser } from "../../../models/Session";
import { Button } from "../../../viewer/components/styled/Button";

/**
 * MembershipRoleEditor component
 *
 * @param {Object} props - Component props
 * @param {Object} props.membership - Membership object
 * @param {Object} props.membership.user - User object
 * @param {string} props.membership.user.id - User ID
 * @param {string} props.membership.user.name - User name
 * @param {string} props.membership.user.email - User email
 * @param {string} props.membership.role - Current role of the membership
 * @param {Function} props.onUpdateRole - Function to update the role
 */

const MembershipRoleEditor = ({ membership, onUpdateRole }) => {
  const [selectedRole, setSelectedRole] = useState(membership.role);
  const roles = ["admin", "viewer"];
  const isRoleChanged = selectedRole !== membership.role;
  const [toast, setToast] = useState({ message: "", type: "", visible: false });

  const sessionUser = getSessionUser() || {};
  if (sessionUser["user_id"] === membership.user.id) {
    return (
      <h2>
        <strong>Current User</strong>
      </h2>
    );
  }

  const showToast = (message, type = "success") => {
    setToast({ message, type, visible: true });
    setTimeout(() => setToast({ message, type, visible: false }), 3000);
  };

  const name = membership.user.name ?? membership.user.email;

  return (
    <div className="flex items-center mr-2">
      <Button
        className="mr-2"
        disabled={!isRoleChanged}
        onClick={async () => {
          if (isRoleChanged) {
            try {
              await onUpdateRole(membership.id, selectedRole);
              setSelectedRole(selectedRole);
              showToast(
                `Role updated successfully to ${selectedRole} for user ${name}`
              );
            } catch (error) {
              showToast("Failed to update role", "error");
            }
          }
        }}
      >
        Update Role
      </Button>
      <select
        className="block w-32 p-1 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 hover:border-gray-400"
        value={selectedRole}
        onChange={(e) => setSelectedRole(e.target.value)}
      >
        {roles.map((role) => (
          <option key={role} value={role}>
            {role}
          </option>
        ))}
      </select>
      {toast.visible && (
        <div
          className={`fixed top-4 right-4 p-4 rounded shadow-lg z-50 ${
            toast.type === "error" ? "bg-red-500" : "bg-primary-500"
          } text-white`}
        >
          {toast.message}
        </div>
      )}
    </div>
  );
};

export default MembershipRoleEditor;
