import styled from "@emotion/styled"

const AnimationStyle = styled.div`
@keyframes colorCycle {
  0% { stroke: #f00; }
  50% { stroke: #ff0; }
  100% { stroke: #f00; }
}
.animate-color-cycle {
  animation: colorCycle 10s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}
`;

export default AnimationStyle;
