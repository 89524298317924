import React, { useEffect, useState } from "react";
import {
  Link,
  Outlet,
  useRouteLoaderData,
  useLocation,
} from "react-router-dom";
import { accountPath } from "../models/Paths";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { CenterContainer } from "../components/styled/CenterContainer";
import { fetchMemberships } from "../api/memberships";

const activeClasses =
  "inline-block p-4 text-primary-600 border-b-2 border-primary-600 rounded-t-lg active dark:text-primary-500 dark:border-primary-500";
const inactiveClasses =
  "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300";

const Account = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  const account = useRouteLoaderData("account");
  const currentUser = account.users.find((user) => user.is_current_user) || {};

  let location = useLocation();

  const activeStates = {
    defaultStageActive: location.pathname.endsWith(account.slug),
    stagesActive: location.pathname.endsWith("stages"),
    membershipsActive: location.pathname.endsWith("memberships"),
    subscriptionActive: location.pathname.endsWith("subscription"),
    settingsActive: location.pathname.endsWith("settings"),
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchedMemberships = await fetchMemberships(account.id);
      
      const userMembership = fetchedMemberships.find(
        m => m.user.id === currentUser.id || 
             (m.user.id && m.user.id.toString() === currentUser.id?.toString())
      );
      setIsAdmin(userMembership?.role === "admin");
    };
    
    if (account) {
      fetchData();
    }
  }, [account, currentUser.id]);

  return (
    <CenterContainer>
      <div className="grow flex h-screen flex-col">
        <div className="flex flex-row text-2xl items-center">
          <FontAwesomeIcon icon={faBuilding} />
          &nbsp;{account.name}
        </div>
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
          <ul className="flex flex-wrap -mb-px">
            <li className="me-2">
              <Link
                className={
                  activeStates.defaultStageActive
                    ? activeClasses
                    : inactiveClasses
                }
                to={accountPath(account.slug)}
              >
                Projects
              </Link>
            </li>
            <li className="me-2">
              <Link
                className={
                  activeStates.stagesActive ? activeClasses : inactiveClasses
                }
                to={accountPath(account.slug) + "/stages"}
              >
                Stages
              </Link>
            </li>
            {isAdmin && (
              <>
                <li className="me-2">
                  <Link
                    className={
                      activeStates.membershipsActive
                        ? activeClasses
                        : inactiveClasses
                    }
                    to={accountPath(account.slug) + "/memberships"}
                  >
                    Memberships
                  </Link>
                </li>
                <li className="me-2">
                  <Link
                    className={
                      activeStates.subscriptionActive
                        ? activeClasses
                        : inactiveClasses
                    }
                    to={accountPath(account.slug) + "/subscription"}
                  >
                    Subscription
                  </Link>
                </li>
                <li className="me-2">
                  <Link
                    className={
                      activeStates.settingsActive
                        ? activeClasses
                        : inactiveClasses
                    }
                    to={accountPath(account.slug) + "/settings"}
                  >
                    Settings
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
        <Outlet />
      </div>
    </CenterContainer>
  );
};

export default Account;