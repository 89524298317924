import { useRouteLoaderData } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Breadcrumbs from '../viewer/components/Breadcrumbs';
import TrialBanner from "./subscription/TrialBanner";
import ProjectHistory from './ProjectHistory';
import { SearchParamsProvider } from '../viewer/contexts/SearchParamsContext';
import WarningLabel from "./WarningLabel";

const StageContainer = () => {
  const account = useRouteLoaderData("account");
  const { stageName } = useRouteLoaderData("stage");
  const stages = useRouteLoaderData("stages");

  const defaultStage = stages.stages.find(
    (stage) => stage.id === account.default_stage_id
  );

  return (
    <>
      <TrialBanner accountId={account.id} />
      <div className="flex flex-row justify-between items-center whitespace-nowrap border-b border-gray-200 pb-2">
        <Breadcrumbs />
        <WarningLabel
          currentStageName={stageName}
          stages={stages.stages}
          defaultStage={defaultStage}
          accountSlug={account.slug}
        />
        <ProjectHistory />
      </div>
      <SearchParamsProvider>
        <Outlet />
      </SearchParamsProvider>
    </>
  );
};

export default StageContainer;
