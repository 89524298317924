import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { ButtonOutline } from "../viewer/components/styled/Button";

const GoToDefaultButton = ({
  stages = [],
  defaultStageId,
  currentStageName,
  accountSlug,
  handleChangeStageName, // Function to handle stage name change
}) => {

  if (isEmpty(stages) || isEmpty(currentStageName)) {
    return null;
  }

  const defaultStage = stages.find((stage) => stage.id === defaultStageId);
  if (isEmpty(defaultStage)) {
    return null;
  }

  if (defaultStage.name === currentStageName) {
    return null;
  }

  return (
    <ButtonOutline className="mr-4">
      <Link
        to={`/${accountSlug}/${defaultStage.name}`}
        onClick={() => handleChangeStageName(defaultStage.name)}
      >
        Go To Default Stage
      </Link>
    </ButtonOutline>
  );
};

export default GoToDefaultButton;
