import React, { useState, useEffect } from "react";
import {
  fetchMemberships,
  deleteMembership,
  updateMembershipRole,
} from "../../api/memberships";
import { fetchInvites, createInvite, deleteInvite } from "../../api/invites";
import { fetchSubscriptions } from "../../api/subscriptions";
import Heading from "../../viewer/components/styled/Heading";
import Input from "../../viewer/components/styled/Input";
import { Button, ButtonHighlight } from "../../viewer/components/styled/Button";
import { useRouteLoaderData, Link } from "react-router-dom";
import Spinner from "../../viewer/components/styled/Spinner";
import { getSessionUser } from "../../models/Session";
import MembershipRoleEditor from "./membership_role_editor/MembershipRoleEditor";
import tw from "tailwind-styled-components"

const Disabled = tw.div`
    flex flex-row bg-highlight-100 justify-center-items p-2 rounded-lg m-2 text-sm
`;

const Memberships = () => {
  const [memberships, setMemberships] = useState(null);
  const [invites, setInvites] = useState(null);
  const [inviteEmail, setInviteEmail] = useState("");
  const [subscriptions, setSubscriptions] = useState(null);

  const account = useRouteLoaderData("account");
  const currentUser =
    account?.users?.find((user) => user.is_current_user) || {};

  const isAdmin = React.useMemo(() => {
    if (!currentUser?.id || memberships === null) return null;

    const userMembership = memberships.find(
      (membership) => membership.user.id === currentUser.id
    );

    return userMembership?.role === "admin" || false;
  }, [memberships, currentUser?.id]);

  useEffect(() => {
    const fetchData = async () => {
      setMemberships(await fetchMemberships(account.id));
    };
    if (account) {
      fetchData();
    }
  }, [account]);

  useEffect(() => {
    const fetchData = async () => {
      setInvites(await fetchInvites(account.id));
    };
    if (account) {
      fetchData();
    }
  }, [account]);

  useEffect(() => {
    const fetchData = async () => {
      setSubscriptions(await fetchSubscriptions(account.id));
    };
    if (account) {
      fetchData();
    }
  }, [account]);

  // Show spinner while memberships are loading even
  // if we don't know if they are an admin user
  if (memberships === null) {
    return (
      <>
        <Heading>Memberships</Heading>
        <Spinner />
      </>
    );
  }

  if (!isAdmin) {
    // This is only front end. If a user wanted to change this
    // they could likely just modify the object
    return <h1>You need to be an admin to view this page</h1>;
  }

  const deleteExistingMembership = async (membership) => {
    await deleteMembership(membership.id);
    setMemberships(await fetchMemberships(account.id));
  };

  const updateUserMembershipRole = async (membershipID, role) => {
    await updateMembershipRole(membershipID, role);
    setMemberships(await fetchMemberships(account.id));
  };

  const deleteExistingInvite = async (invite) => {
    await deleteInvite(invite.id);
    setInvites(await fetchInvites(account.id));
  };

  const createNewInvite = async (event) => {
    if (!addingUserEnabled()) {
      return;
    }
    if (!/\S+@\S+\.\S+/.test(inviteEmail)) {
      alert("Please enter a valid email address.");
      return;
    }
    const newInvite = await createInvite(inviteEmail, account);
    if (newInvite) {
      setInvites([...invites, newInvite]);
    }
  };
  const handleEmailChange = (event) => {
    setInviteEmail(event.target.value);
  };

  const addingUserEnabled = () => {
    if (!subscriptions || !subscriptions[0]) {
      return false;
    }
    const subscription = subscriptions[0];
    return subscription.can_add_users
  };

  const renderDeleteMembership = (membership) => {
    if (!membership.user) {
      return null;
    }
    if (getSessionUser()["user_id"] === membership.user.id) {
      return null;
    }
    return (
      <ButtonHighlight onClick={() => deleteExistingMembership(membership)}>
        Delete
      </ButtonHighlight>
    );
  };

  const renderMemberships = () => {
    const heading = "Memberships";
    if (memberships === null || memberships === undefined) {
      return (
        <>
          <Heading>{heading}</Heading>
          <Spinner />
        </>
      );
    }

    return (
      <>
        <Heading>{heading}</Heading>
        <ol>
          {memberships
            .sort((a, b) => b.user.is_current_user - a.user.is_current_user)
            .map((membership) => (
              <li
                key={membership.id}
                className="flex flex-row justify-between items-center hover:bg-secondary-100 p-1"
              >
                <div className="flex flex-row">
                  <div className="mr-4">
                    <span className="font-light mr-2">Display Name:</span>
                    {membership.user.name ? membership.user.name : "(Not Set)"}
                  </div>
                  <div>
                    <span className="font-light mr-2">Email:</span>
                    {membership.user.email}
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <MembershipRoleEditor
                    membership={membership}
                    onUpdateRole={updateUserMembershipRole}
                  />
                  {renderDeleteMembership(membership)}
                </div>
              </li>
            ))}
        </ol>
      </>
    );
  };

  const renderInviteButton = () => {
    const isValidEmail = (email) => {
      return email.includes("@") && email.includes(".") && email.length > 5;
    };

    if (addingUserEnabled()) {
      return (
        <div className="flex flex-row">
          <Input
            label="Email to invite"
            name="email"
            alt="inviteEmail"
            value={inviteEmail}
            onChange={handleEmailChange}
          />
          <Button
            className="ml-2"
            onClick={createNewInvite}
            disabled={!isValidEmail(inviteEmail)}
          >
            Invite
          </Button>
        </div>
      );
    }else if(!subscriptions){
        return null
    }else{
        return (
            <Disabled>
                <Link to="../subscription">Upgrade your plan to add more users.</Link>
            </Disabled>
        )
    }
  };

  const renderInvites = () => {
    const heading = "Invites";
    if (!invites) {
      return (
        <>
          <Heading>{heading}</Heading>
          <Spinner />
        </>
      );
    }

    return (
      <>
        <Heading>
          <div>{heading}</div>
          {renderInviteButton()}
        </Heading>
        <ol>
          {invites.map((invite) => (
            <li
              key={invite.id}
              className="flex flex-row justify-between hover:bg-gray-100 items-center p-1"
            >
              <div>{invite.email}</div>
              <ButtonHighlight onClick={() => deleteExistingInvite(invite)}>
                Delete
              </ButtonHighlight>
            </li>
          ))}
        </ol>
      </>
    );
  };

  return (
    <>
      {renderMemberships()}
      {renderInvites()}
    </>
  );
};

export default Memberships;
